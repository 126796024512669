import {PrimaryButton} from '@nib-components/button';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import {Footer, Layout, SEO} from '@nib/gatsby-theme-mesh-docs';
import {NotSoGoodGraphicIcon, TickInCircleGraphicIcon} from '@nib/icons';
import {Box} from '@nib/layout';
import React, {useState} from 'react';
import Banner from '../components/banner';
import {FeedbackForm} from '../components/feedback-form';
import {getReCaptchaToken} from '../components/feedback-form/recaptcha';
import Header from '../components/header/Header';
import {PLATFORMS_BASEURL} from '../constants';

const FeedbackPage = () => {
  const [endState, setEndState] = useState(undefined);

  const handleSubmit = async evt => {
    try {
      const formId = '7caa57ba-e83c-4ec9-8500-69da6f5453c8';
      const endpoint = new URL(`/v1/forms/${formId}/submissions`, PLATFORMS_BASEURL);

      const formData = new FormData(evt.currentTarget);
      const token = await getReCaptchaToken();

      formData.append('gReCaptchaToken', token);

      const queryString = new URLSearchParams(formData).toString();

      const response = await fetch(endpoint.href, {
        method: 'POST',
        body: queryString,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.status !== 201) {
        setEndState('error');
      } else {
        setEndState('success');
      }
    } catch (err) {
      setEndState('error');
    }
  };

  return (
    <Layout bannerContent={<Banner />}>
      <SEO title="Feedback form | Mesh" />

      <Header />

      <Box display="flex" flexDirection="column" alignItems="center" gap={6} marginBlock={6}>
        {endState === 'success' ? (
          <>
            <TickInCircleGraphicIcon fill="trueGreen" />

            <Heading fontType="serif" component="h2" size={2}>
              Thank you!
            </Heading>

            <Heading align="center" component="h3" size={3}>
              Your response has been recorded.
            </Heading>

            <PrimaryButton onClick={() => setEndState(undefined)}>Submit another response</PrimaryButton>
          </>
        ) : endState === 'error' ? (
          <>
            <NotSoGoodGraphicIcon fill="trueGreen" />

            <Heading align="center" fontType="serif" component="h2" size={2}>
              Don&apos;t worry, it&apos;s not you...
            </Heading>

            <Heading align="center" component="h3" size={3}>
              Oops, we hit a hurdle! Your submission wasn&apos;t sent.
            </Heading>

            <p>
              Give us a few minutes, and then try resubmitting. If this happens again, <Link href="mailto:designops@nib.com.au">contact us</Link>.
            </p>

            <PrimaryButton onClick={() => setEndState(undefined)}>Let&apos;s try again</PrimaryButton>
          </>
        ) : (
          <FeedbackForm handleSubmit={handleSubmit} />
        )}
      </Box>

      <Footer />
    </Layout>
  );
};

export default FeedbackPage;
