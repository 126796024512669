import React from 'react';
import {CAPTCHA_SITE_KEY} from '../../constants';

export const getReCaptchaToken = () => {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(async function () {
      try {
        const token = await window.grecaptcha.execute(CAPTCHA_SITE_KEY, {action: 'submit'});
        resolve(token);
      } catch (err) {
        reject(err);
      }
    });
  });
};

export const ReCaptchaScript = () => {
  return <script src={`https://www.google.com/recaptcha/api.js?render=${CAPTCHA_SITE_KEY}`} defer />;
};
