import {PrimaryButton} from '@nib-components/button';
import Form from '@nib-components/form';
import {FormControl} from '@nib-components/form-control';
import Textarea from '@nib-components/textarea';
import Textbox from '@nib-components/textbox';
import {FileUpload} from '@nib/file-upload-component';
import {Box} from '@nib/layout';
import React, {useState} from 'react';
import {FILE_UPLOAD_API_URL} from '../../constants';
import {ReCaptchaScript} from './recaptcha';

// Copied from zod: https://github.com/colinhacks/zod/blob/8552233c77426f77d3586cc877f7aec1aa0aa45b/src/types.ts#L599C1-L600C88
const EMAIL_REGEX = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;

/**
 * @param {{ handleSubmit: FormEventHandler<HTMLFormElement>}} props
 */
export const FeedbackForm = ({handleSubmit}) => {
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({
    name: {value: '', isValid: false, isValidated: false},
    email: {value: '', isValid: false, isValidated: false},
    feedback: {value: '', isValid: false, isValidated: false},
    screenshots: {value: '', isValid: true, isValidated: false}
  });

  /**
   * @param {(val: string) => boolean} predicate
   * @returns {ChangeEventHandler<HTMLInputElement>}
   */
  const buildChangeHandler = predicate => {
    return async evt => {
      const {name, value} = evt.target;

      setData(prev => ({
        ...prev,
        [name]: {value, isValid: predicate(value), isValidated: prev[name].isValidated}
      }));
    };
  };

  const handleBlur = () => {
    setValidated(true);
  };

  return (
    <Box width="100%">
      <Form id="mesh-feedback" name="mesh-feedback" title="Feedback" onSubmit={handleSubmit}>
        <FormControl id="name" name="name" label="Name" formControlType="required" validated={validated || data.name.isValidated} valid={data.name.isValid}>
          <Textbox value={data.name.value} onChange={buildChangeHandler(val => val.length)} onBlur={handleBlur} autocomplete="name" />
        </FormControl>

        <FormControl id="email" name="email" label="Email" formControlType="required" validated={validated || data.email.isValidated} valid={data.email.isValid}>
          <Textbox value={data.email.value} onChange={buildChangeHandler(val => EMAIL_REGEX.test(val))} onBlur={handleBlur} autocomplete="email" />
        </FormControl>

        <FormControl id="feedback" name="feedback" label="What is your feedback?" formControlType="required" validated={validated || data.feedback.isValidated} valid={data.feedback.isValid}>
          <Textarea value={data.feedback.value} onChange={buildChangeHandler(val => val.length)} onBlur={handleBlur} />
        </FormControl>

        <FormControl
          name="screenshots-file"
          label="Please attach any relevant screenshots"
          validated={validated || data.screenshots.isValidated}
          valid={data.screenshots.isValid}
          help="Supported file types: png, jpg, etc. (<5mb)"
        >
          <FileUpload
            gatewayEndpoint={FILE_UPLOAD_API_URL}
            maxFileSizeLimitInMB={5}
            setFilesReadyForUpload={isValid => {
              if (data.screenshots.isValid !== isValid) {
                setData({
                  ...data,
                  screenshots: {...data.screenshots, isValid, isValidated: true}
                });
              }
            }}
            setFilesOnParent={({sessionId}) => {
              if (sessionId !== data.screenshots.value) {
                setData({...data, screenshots: {...data.screenshots, value: sessionId}});
              }
            }}
          />
        </FormControl>

        <input name="screenshots" type="hidden" value={data.screenshots.value} />

        <PrimaryButton type="submit">Submit</PrimaryButton>
        <ReCaptchaScript />
      </Form>
    </Box>
  );
};
